<template>
  <div class="action-block new-block">
    <div v-if="['copy', 'copyAll'].includes(actionType)" class="new-action">
      <span class="btn btn-sm btn-outline-primary" @click="onActionConfirm">Copy Here</span>
    </div>

    <div v-else-if="['move', 'moveAll'].includes(actionType)" class="new-action">
      <span class="btn btn-sm btn-outline-primary" @click="onActionConfirm">Move Here</span>
    </div>

    <div v-else class="new-action" @click="showActionsModal">
      <i class="fa fa-plus-circle"></i>
    </div>

    <vuestic-modal :isOpen="isShowActionsModal" @cancel="isShowActionsModal = false" :okShown="false" :cancelShown="false"
      cancelClass="btn btn-danger" class="new-action-modal">
      <span slot="title" class="text-primary font-weight-bold">Add a New Action</span>
      <div class="actions-section">
        <div class="list-groups">
          <div v-for="(group, index1) in actions" :key="`group-${index1}`" :class="{ 'active': index1 === selectedGroup }"
            class="group-item" @click="selectGroup(index1)">
            {{ group.label }} <i class="fa fa-angle-right"></i>
          </div>
        </div>
        <div class="list-actions">
          <div v-for="(action, index2) in selectedActions" :key="`action-${index2}`" class="action-item"
            @click="selectAction(action)">
            <div class="icon">
              <i :class="action.icon"></i>
            </div>
            <div class="desc">
              <div class="name">
                {{ action.name }}
              </div>
              <p>
                {{ action.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: [Number, null],
      default: null,
    },

    level: {
      type: String,
      default: '1'
    },

    actionType: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      isShowActionsModal: false,
      selectedGroup: null,
    }
  },

  computed: {
    actions() {
      return this.$store.getters['automation/actions'] || []
    },

    selectedActions() {
      return this.actions[this.selectedGroup ? this.selectedGroup : Object.keys(this.actions)[0]].actions
    }
  },

  methods: {
    onActionConfirm() {
      this.$emit('onActionConfirm', {
        index: this.index, level: this.level
      })
    },

    showActionsModal() {
      this.isShowActionsModal = !this.isShowActionsModal
    },

    selectGroup(group) {
      this.selectedGroup = group
    },

    selectAction(action) {
      this.$emit('selected', { action: action, index: this.index, level: this.level })
      this.isShowActionsModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.new-action {
  cursor: pointer;
  text-align: center;

  .btn {
    margin-top: 2px;
    margin-bottom: 8px;
  }
}

.fa {
  font-size: 20px;

  &:hover {
    color: #5f667e;
  }
}

.list-actions {
  .action-item {
    background-color: #65758A;
    color: #FFF;
  }
}
</style>

<style lang="scss">
.new-action-modal {
  text-align: left;

  .actions-section {
    display: flex;
    min-height: 300px;
  }

  .modal-body {
    padding: 0;
  }

  .list-groups {
    flex: 0 0 250px;
    background: #4e5468;

    .group-item {
      background: #4e5468;
      color: #FFF;
      padding: 16px 12px;
      font-weight: 700;
      border-bottom: 1px solid #353a4a;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: #353a4a;
      }

      .fa {
        float: right;
      }
    }
  }

  .list-actions {
    flex: 1 1 auto;
    padding: 0px 15px;

    .action-item {
      display: flex;
      padding: 8px 5px;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      margin-bottom: 10px;

      .icon {
        flex: 0 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .desc {
        flex: 1 0;

        .name {
          font-weight: 700;
          cursor: pointer;
        }

        p {
          margin-bottom: 0px;
          line-height: 15px;
          font-size: 12px;
        }
      }
    }
  }
}</style>